@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.gradient_btn {
  background: linear-gradient(
    103.91deg,
    #9b51e0 21.01%,
    rgba(48, 129, 237, 0.8) 100%
  );
}

.pink_gradient {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 123px;
  height: 123px;
  border-radius: 50%;

  background: #fb37ff;
  filter: blur(200px);
}

.blue_gradient {
  position: absolute;
  bottom: 0%;
  right: 0%;
  width: 123px;
  height: 123px;
  border-radius: 50%;

  background: #18b2de;
  filter: blur(200px);
}

.gradient-border {
  background: linear-gradient(
    168.82deg,
    #fb37ff 1.7%,
    rgba(155, 111, 238, 0) 27.12%,
    rgba(123, 127, 234, 0) 61.28%,
    #1bb2de 99.52%
  );
}