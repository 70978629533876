@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');

@layer components {
  .all-\[unset\] {
    all: unset;
  }
}

:root {
  --body-text-font-family: "Courier Prime", Helvetica;
  --body-text-font-size: 18px;
  --body-text-font-style: normal;
  --body-text-font-weight: 400;
  --body-text-letter-spacing: 1.8px;
  --body-text-line-height: 20px;
  --header-1-font-family: "Courier Prime", Helvetica;
  --header-1-font-size: 35px;
  --header-1-font-style: normal;
  --header-1-font-weight: 700;
  --header-1-letter-spacing: 3.5px;
  --header-1-line-height: 20px;
  --header-font-family: "Courier Prime", Helvetica;
  --header-font-size: 20px;
  --header-font-style: normal;
  --header-font-weight: 400;
  --header-letter-spacing: 2px;
  --header-line-height: 34px;
}